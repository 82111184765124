html, body {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  line-height: 1.47;
  color: #7b7b7b;
}

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

::selection {
  background: #004799;
  color: #fff;
}

button {
  font-family: "Source Code Pro", monospace;
}

button:hover, a:hover {
  cursor: pointer;
}

.mapContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.map {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}

h3, h4, .ant-typography h3 h4 {
  font-size: 32px;
  line-height: 1.44;
  letter-spacing: 0.9px;
  font-weight: normal;
}

.ant-modal-header, .ant-modal-body {
  background-color: transparent;
  font-size: 16px;
  letter-spacing: 0.9px;
  line-height: 1.64;
  font-family: "EB Garamond", serif;
  font-weight: normal;
  text-align: center;
}
.ant-form {
  margin: auto;
  background: white;
  padding: 60px 30px;
}

.ant-message{
  top: 6px

}

.ant-message-notice {
  padding: 10px !important;
}


.ant-message-notice-content{
  padding: 2px 45px !important;
  background-color: #004799 !important;
  border-radius: 4px !important;
  color: #FFFFFF;
}

/* original dims are 2769 * 1935... */
.helenaLogo{
  z-index: 99;
  position: absolute;
  bottom: 15px;
  right: 15px;
  height: 30px;
  width: 42px;
}



.ant-message-success .anticon {
  color: #FFFFFF !important;

}
