.navbar, .navbar-menu, .navbar .ant-menu-vertical {
    width: 100%;
    padding: 0px;
    background: linear-gradient(to bottom, rgba(255,255,255,0.91) 0%,rgba(255,255,255,0.82) 64%,rgba(255,255,255,0) 100%);
    z-index: 2000;
    display: flex;
    color: #545454;
}


.navbar-menu:hover {
    background-color: #f8f8f8;
}

.navbar .ant-menu-item, .navbar .ant-menu-submenu{
    color: #545454;
    font-family: "Source Code Pro";
    font-size: 14px;
    border-left: 1px solid #f8f8f8 !important;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    
    /* #padding: 32px 20px 28px */
}

.ant-menu-sub > .ant-menu-item, .ant-menu-sub{
    color: #545454 !important;
    font-family: "Source Code Pro";
    font-size: 14px;
    border-left: 1px solid #f8f8f8 !important;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    background: #FFFFFF !important;
    /* #padding: 32px 20px 28px */
}

.navbar .ant-menu-item ::selection {
    color: #545454;
    font: "Source Code Pro";
    
    background-color: inherit;
}


.ant-menu-item:hover {
    color: #004799 !important;
    font: "Source Code Pro";
    
    background-color: white
}

.navbar .ant-menu-item-active, .navbar .ant-menu-item-selected {
    background: linear-gradient(to bottom, rgba(255,255,255,0.91) 0%,rgba(255,255,255,0.82) 64%,rgba(255,255,255,0) 100%) !important;
    color: #004799;

}

