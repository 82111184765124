.RequestList {
  flex: 1 1 auto;
  overflow: auto;
  min-height: 1px;
}

.RequestList li:nth-child(odd) {
  background-color: rgba(251, 251, 251, 1);
}

.RequestList-solveThis {
  outline: none;
  /* min-width: 60px; */
  height: auto;
  border: 1px solid rgba(200, 200, 200, 1);
  background-color: none;
  color: rgba(204, 36, 1, 1);
  font-weight: 800;
  font-size: 10px;
  font-family: "Source Code Pro", monospace;
  text-align: right;
  text-transform: uppercase;
  transition-property: border-color, color;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  margin-right: 10px;
}

.RequestList-solveThis:hover {
  border-color: rgba(171, 171, 171, 1);
  color: rgba(204, 36, 1, 0.6);
}

.RequestList .requestMeta {
  color: rgba(151, 151, 151, 1);
  font-size: 11px;
}

.Request-type, .Request-location {
  flex: 1 1 auto;
  width: auto;
}

.Request-date, .Request-quantity {
  flex: 0 1 auto;
  width: auto;
}

.Request-details, .Request-meta {
  flex-wrap: nowrap;
  width: 100%;
}

.Request-details {
  font-weight: 700;
}

.RequestList .ant-descriptions-small {
  margin-left: 15px;
}

/* .RequestList .ant-descriptions-item-content {
 line-height: 0;
} */
.RequestList .ant-descriptions-item-label {
  line-height: 0;
 }

 .RequestList .ant-descriptions-item-content h1 {
  font-family: "Source Code Pro", monospace;
 }