@media (min-width: 320px) {
  .layerControls {
    position: absolute;
    left: 15px;
    bottom: 0px;
  }

  .layerControls ul li ul li {
    white-space: nowrap;
    height: 34px;
  }

  .layerControls ul:hover {
    height: auto;
  }

  .countyLayers img,
  .hospitalLayers img {
    transition: all 0.25s ease;
  }
}

.layerControls > ul > li:hover > .countyLayers img:nth-child(1),
.layerControls > ul > li:hover > .hospitalLayers img:nth-child(1) {
  opacity: 0;
}

.layerControls > ul > li:click > .countyLayers img:nth-child(1),
.layerControls > ul > li:hover > .hospitalLayers img:nth-child(1) {
  opacity: 0;
}

.layerControls > ul > li:hover > .hospitalLayers img:nth-child(2),
.layerControls > ul > li:hover > .countyLayers img:nth-child(2) {
  opacity: 1;
}

.hospitalLayers,
.countyLayers {
  transition: opacity 0.25s ease;
  /* box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2); */
}

.layerControls > ul > li ul {
  overflow: hidden;
  transform: bottom;
  max-height: 0;
  width: fit-content;
  transition: max-height 0.25s ease;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
}

.layerControls > ul > li:hover ul {
  overflow: visible;
  position: relative;
  left: -3px;
  max-height: 800px;
}

.layerControls > ul > li:hover > .countyLayers {
  background-color: rgba(0, 116, 185, 1);
  transition: all 0.25s ease;
}

.layerControls > ul > li:hover > .hospitalLayers {
  background-color: rgba(243, 1, 0, 1);
  transition: all 0.25s ease;
}

.layerControls > ul > li:hover {
  width: 250px;
  max-height: 800px;
}

.countyLayers,
.hospitalLayers {
  position: relative;
  left: -7px;
  width: 36px;
  height: 36px;
  margin-bottom: 12px;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 1);
}

.countyLayers .label,
.hospitalLayers .label {
  
  position: relative;
  top: 3px;
  left: 37px;
  text-transform: uppercase;
}

.countyLayers .label div,
.countyLayers .label span,
.hospitalLayers div,
.hospitalLayers span {
  display: inline-block;
  white-space: nowrap;
}

.layerControls .label span {
  cursor: pointer;
  font-family: 'Source Code Pro', monospace;
  font-size: 14px;
  font-weight: 800;
}

.countyLayers .label div,
.hospitalLayers .label div {
  /* opacity: 0.5; */
  position: relative;
  top: -1px;
  padding-left: 10px;
  font-size: 11px;
}

.countyLayers img,
.hospitalLayers img {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
}

.countyLayers img:nth-child(2),
.hospitalLayers img:nth-child(2) {
  opacity: 0;
}

.countyLayers + ul > li,
.hospitalLayers + ul > li {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  /* margin: 8px 0; */
}

/* .layerControls li .legend {
  display: inline-block;
  flex: 0 0 auto;
  box-sizing: border-box;
  transform: scale(1);
  width: 30px;
  height: 30px;
  margin-bottom: 0;
  border-radius: 50%;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  transition: transform 0.33s ease;
} */

.layerControls li:hover .legend {
  transform: scale(1.2777777777);
}

.layerControls li .label {
  display: inline-block;
  flex: 1 1 auto;
  min-height: 30px;
  margin-left: 6px;
  padding: 0 15px;
  /* border-radius: 36px;
  background-color: rgba(255, 255, 255, 1); */
  /*
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 1); */
  /* background-color: rgba(255, 255, 255, 1); */
  font-size: 12rem;
  line-height: 30px;
  white-space: nowrap;
}

.layerControls li .header {
  display: inline-block;
  flex: 1 1 auto;
  min-height: 30px;
  margin-left: 6px;
  padding: 2px 20px;
  border-radius: 36px;
  background-color: rgba(255, 255, 255, 1);
  font-size: 14px;
  /* box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2); */
  background-color: rgba(255, 255, 255, 1); 
}


.layerControls ul ul .label {
  position: relative;
  display: inline-block;
  flex: 1 1 auto;
  min-height: 30px;
  margin-left: 6px;
  padding: 0 15px;
  /* background-color: rgba(255, 255, 255, 1); */
  font-size: 12rem;
  line-height: 30px;
  white-space: nowrap;
}

.layerControls ul ul .legend + .label {
  margin-left: 12px;
}

.layerControls .label .ant-switch {
  position: relative;
  margin-left: -8px;
}

.layerControls .legend {
  position: relative;
}

.layerControls .on .legend {
  border: 2px solid rgba(1, 185, 78, 1);
}

.layerControls .legend > div {
  transform: scale(1);
  /* opacity: 0.5; */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 60%;
  height: 60%;
  margin: auto;
  border-radius: 50%;
  transition-property: transform, opacity;
  transition-duration: 0.25s;
  transition-timing-function: ease;
}

.layerControls ul ul li:hover .legend > div,
.layerControls .on .legend > div {
  transform: scale(1.166666667);
  opacity: 1;
}

.ant-checkbox-checked::after {
  border: none;
}

.countyChecks li:nth-child(1) .ant-checkbox-inner {
  /* border: 2px solid #d18452; */
  background: #d18452;
  border-radius: 50%;
}

.countyChecks li:nth-child(1)  .ant-checkbox-checked .ant-checkbox-inner {
  background: #d18452;
}


.countyChecks li:nth-child(2) .ant-checkbox-inner {
  background: #c66c3e;
  border-radius: 50%;
}

.countyChecks li:nth-child(3) .ant-checkbox-inner {
  background: #b9542a;
  border-radius: 50%;
}

.countyChecks li:nth-child(4) .ant-checkbox-inner {
  background: #ac3b15;
  border-radius: 50%;
}

.countyChecks li:nth-child(5) .ant-checkbox-inner {
  background: #0bb7e7;
  border-radius: 50%;
}

.countyChecks li:nth-child(6) .ant-checkbox-inner {
  background: #6727ff;
  border-radius: 50%;
}


.countyChecks li:nth-child(7) .ant-checkbox-inner {
  background: #137ce7;
  border-radius: 50%;
}

.countyChecks li:nth-child(8) .ant-checkbox-inner {
  background: #1ee2f7;
  border-radius: 50%;
}

.facChecks li:nth-child(1) .ant-checkbox-inner {
  background: #137ce7;
  border-radius: 50%;
}

.facChecks li:nth-child(2) .ant-checkbox-inner {
  background: #1ee2f7;
  border-radius: 50%;
}


.layerControls .legend .loading {
  opacity: 0;
}


.ant-badge-status-dot {
  width: 10px;
  height: 10px;
}

.loading .legend .loading {
  opacity: 1;
}

.label .loading {
  display: inline;
  overflow: hidden;
  opacity: 0;
  width: 0;
  transition: opacity 0.25s ease;
}

.loading .label {
  color: rgba(0, 0, 0, 0.4);
}

.loading .label .loading {
  opacity: 1;
  width: auto;
}

.label .loading .loadBar {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 33px;
  height: 6px;
  border-radius: 6px;
  background-color: rgba(216, 216, 216, 1);
}

.label .loadBar .progress {
  width: 50%;
  height: 6px;
  border-radius: 6px;
  background-color: rgba(1, 185, 78, 1);
  /* transition: all 0.25s ease; */
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  .rotating {
    -webkit-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
  }

  /* TODO: wtf is this? */
  /*
  .loading .label .loading {
    opacity: 1;
    width: auto;
  }

  .label .loading .loadBar {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 33px;
    height: 6px;
    border-radius: 6px;
    background-color: rgba(216, 216, 216, 1);
  }

  .label .loadBar .progress {
    width: 50%;
    height: 6px;
    border-radius: 6px;
    background-color: rgba(1, 185, 78, 1);
    transition: all 0.25s ease;
  }
  .rotating {
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
  }

  @-webkit-keyframes rotating {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  */
}

@media (min-width: 720px) {
  .layerControls .legend {
    width: 36px;
    height: 36px;
  }

  .layerControls .label {
    height: 36px;
    font-size: 14rem;
    line-height: 36px;
  }

  .layerControls ul li ul li {
    opacity: 0;
    height: auto;
    transition: opacity 0.375s ease;
  }

  .layerControls ul:hover li ul li {
    opacity: 1;
  }

  .layerControls ul:hover li ul li:nth-child(2) {
    transition-delay: 0.025s;
  }

  .layerControls ul:hover li ul li:nth-child(2) {
    transition-delay: 0.05s;
  }

  .layerControls ul:hover li ul li:nth-child(3) {
    transition-delay: 0.075s;
  }

  .layerControls ul:hover li ul li:nth-child(4) {
    transition-delay: 0.1s;
  }

  .layerControls ul:hover li ul li:nth-child(5) {
    transition-delay: 0.125s;
  }

  .layerControls ul:hover li ul li:nth-child(6) {
    transition-delay: 0.15s;
  }

  .layerControls ul:hover li ul li:nth-child(7) {
    transition-delay: 0.175s;
  }

  .layerControls ul:hover li ul li:nth-child(8) {
    transition-delay: 0.2s;
  }

  .layerControls ul:hover li ul li:nth-child(9) {
    transition-delay: 0.225s;
  }


  .countyLayers .label,
  .hospitalLayers .label {
    top: 6px;
    left: 53px;
    cursor: pointer;
  }

  .countyLayers,
  .hospitalLayers {
    width: 50px;
    height: 50px;
  }

  .layerControls > ul > li:hover ul {
    left: 0;
  }
  
  .ant-badge-status-processing {
    font-weight: 800;
  }
  
}
