.search {

  justify-content: center;
  align-items: center;
  flex: 100%;
}

.search .ant-select {
  font-size: 14px;
  font-family: "Source Code Pro", sans-serif;
  font-weight: 500;
  width: 100%;
}

.ant-select-arrow {
  margin-top: -10px;
  font-size: 18px;
  width: 16px;
} 

.ant-select-multiple .ant-select-selector {
  border-radius: 4px;
  border: 1px solid #004799;
  min-height: 40px;
}
/*
.ant-select-multiple .ant-select-selection-item {
  margin: 4px;
}

*/