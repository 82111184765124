@media (min-width: 320px) {
  .requestDrawer {
    display: flex;
    top: 80px;  
    flex-flow: column nowrap;
    transform: translateX(-100%);
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    transition: transform 0.33s ease;
  }

    .requestDrawer.mobile-request {
      color:blue;
      top: 60px;
    }



  .requestDrawer.open {
    transform: translateX(0);
  }

  .requestDrawer.closed {
    transform: translateX(-100%);
  }

  .requestDrawer .RequestList {
    flex: 1 1 auto;
    min-height: 0;
  }

  .drawerTop {
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 15px 12px 8px;
  }

  .drawerTop h2 {
    flex: 0 0 auto;
    padding-right: 12px;
    color: rgba(204, 37, 2, 1);
    font-size: 16px;
    text-transform: uppercase;
  }

  .drawerTop h2 img {
    position: relative;
    top: 6px;
    vertical-align: middle;
    margin-right: 5px;
  }

  .drawerTop .newRequest {
    outline: none;
    height: 36px;
    margin-right: 10px;
    padding: 0 15px 0 10px;
    border: none;
    border-radius: 5px;
    /* box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2); */
    background-color:#004799;
    color: rgba(255, 255, 255);
    font-size: 14px;
    line-height: 36px;
    transition: background-color 0.25s ease;
  }
  
  .dawerTop .newRequest:hover {
    background-color: rgba(41, 71, 157, 1);
  }
  
  .drawerTop .newRequest img {
    position: relative;
    top: -2px;
    margin-right: 5px;
  }

  .drawerTop span {
    flex-basis: 100%;
    margin-left: 31px;
    color: rgba(140, 140, 140, 1);
    font-size: 11px;
  }

  .drawerTop .search {
    flex: 1 1 auto;
    min-width: 0;
  }

  .drawerBottom {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 12px;
    color: rgba(178, 178, 178, 1);
    font-size: 12px;
    font-family: "Source Code Pro", monospace;
    text-transform: uppercase;
  }

  .drawerBottom::after {
    content: "";
    display: block;
    opacity: 0.3;
    position: absolute;
    right: 0;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 10px;
    background-image: linear-gradient(180deg, #fff 0%, #d8d8d8 100%);
  }

  .drawerBottom img {
    width: 100px;
  }

  .drawerBottom span {
    flex: 1 1 auto;
    margin-right: 12px;
    margin-left: 12px;
  }

  .drawerBottom a {
    display: inline-block;
    padding: 5px;
    border: 1px solid rgba(235, 235, 235, 1);
    color: rgba(178, 178, 178, 1);
  }

  .requestDrawer .drawerControls {
    /* transform: translateX(65px); */
    position: absolute;
    top: 15px;
    right: 15px;
    transition: transform 0.25s ease;
  }

  .requestDrawer.open .drawerControls {
    transform: translateX(0);
  }

  .drawerControls button {
    outline: none;
    position: relative;
    z-index: 1000;
    width: 36px;
    height: 36px;
    border: 2px solid rgba(255, 255, 255, 1);
    border-radius: 20px;
    /* box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2); */
    background-color: rgba(255, 255, 255, 1);
    transition: all 0.33s ease;
  }

  .drawerControls button img {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    height: 20px;
    transition: all 0.25s ease;
  }

  .drawerControls button .requestCount {
    /* display: none; */
    position: absolute;
    top: 0;
    right: -30px;
    height: 16px;
    padding: 0 5px;
    border-radius: 8px;
    background-color: rgba(188, 54, 25, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 11px;
    line-height: 16px;
    transition: all 0.33s ease;
  }

  .open .drawerControls button {
    width: 40px;
    height: 40px;
  }

  .drawerControls button .requestCount {
    display: block;
    position: absolute;
    top: 0;
    right: -20px;
    padding: 0 5px;
    border-radius: 10px;
    background-color: rgba(188, 54, 25, 1);
    color: rgba(255, 255, 255, 1);
    transition: all 0.33s ease;
  }

  .open .drawerControls button {
    width: 40px;
    height: 40px;
  }

  .drawerControls button .closeIcon {
    opacity: 0;
  }


  .open .drawerControls button .closeIcon {
    opacity: 1;
  }

  .open .drawerControls button .openIcon {
    opacity: 0;
  }

  .open .drawerControls button .requestCount {
    opacity: 0;
  }
}

.hospitalLayers span,
.countyLayers span {
  font-weight: 700;
}

.hospitalLayers span {
  color: rgba(204, 37, 2, 1);
}

.countyLayers span {
  color: #004799;
}

.drawerControls button {
  outline: none;
  position: relative;
  z-index: 1000;
  width: 36px;
  height: 36px;
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 20px;
  /* box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2); */
  background-color: rgba(255, 255, 255, 1);
  transition: all 0.33s ease;
}

.noRequestsAvailable {
  top: 50%;
  padding: 20px;
  text-align: center;
}

.noRequestsAvailable h2 {
  margin: 10px 20px;
  font-size: 24px;
  font-family: "EB Garamond", serif;
}

.noRequestsAvailable p {
  font-size: 16px;
  line-height: 20px;
}

.noRequestsAvailable img {
  margin-left: 7px;
}

.noRequestsAvailable .hospital, .noRequestsAvailable .county {
  display: inline-block;
  margin-left: 3px;
  font-weight: 700;
}

.noRequestsAvailable .hospital {
  color: rgba(188, 54, 25, 1);
}

.noRequestsAvailable .county {
  color: #004799;
}

@media (min-width: 480px) {
  .requestDrawer {
    width: 360px;
  }
}

.RequestList .ant-descriptions-item-content {
  line-height: inherit;
}