

.helenaBrand {
  position: relative;
  top: 15px;
  left: 15px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px;
}

.helenaBrand img {
  height: 20px;
}

.helenaBrand span {
  display: inline-block;
  position: relative;
  top: 2px;
  left: 5px;
}

.closeButton {
  outline: none;
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  background: none;
}

.closeButton img {
  height: 20px;
}

.formContainer {
  background-color: #FFFFFF;
}

.signInForm {
  background-color: rgba(255, 255, 255, 1);
}

.signInInputs {
  padding: 25px;
}

.ant-input {
  background-color: rgba(248, 248, 248, 1);
  font-size: 16px;
}

.callToAction {
  margin-top: 60px;
  font-size: 16px;
  text-align: center;
}

.callToAction strong {
  display: block;
  margin-bottom: 10px;
}

.formDescription {
  margin-bottom: 60px;
  color: rgba(84, 85, 83, 1);
  font-size: 16px;
  text-align: center;
}

.formDescription h2 {
  margin-bottom: 30;
  color: #0173b9;
  font-size: 36px;
  font-family: "EB Garamond", sans-serif;
}

.signInInputs {
  font-family: "Source Code Pro", monospace;
}

.formInputh2 {
  margin-bottom: 30;
  color: #0173b9;
  font-size: 36px;
  font-family: "EB Garamond", sans-serif;
}

.formBottom {
  padding: 25px;
  background-color: rgba(246, 246, 246, 1);
}

.formBottom .ant-form-item {
  margin-bottom: 0;
}
