.ant-modal-mask {
  background-color: rgba(255, 255, 255, 0);
}

.Modal-wrap--opaque {
  display: flex;
  padding: 24px;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(5px);
}

.Modal-wrap--opaque::before {
  content: normal;
  display: none;
}

.Modal--opaque {
  max-width: 100%;
  margin: auto;
}

.Modal--opaque .ant-modal-content {
  position: static;
  padding-top: 25px;
  box-shadow: none;
  background-color: transparent;
  
  font-size: 16px;
}

.Modal--opaque .ant-modal-header {
  display: flex;
  background-color: transparent;
  font-size: 36px;
  letter-spacing: 0.9px;
  line-height: 1.64;
  font-family: "EB Garamond", serif;
  font-weight: normal;
  text-align: center;
}

.Modal--opaque .ant-modal-body {
  position: relative;
  padding: 15px 0;
  background: #fff;
}

.Modal--opaque .ant-modal-header h1,
.Modal--opaque .ant-modal-header h2,
.Modal--opaque .ant-modal-header h3{
  color: #004799;
  font-weight: 400;
}

.Modal--opaque .ant-modal-header h4,
.Modal--opaque .ant-modal-header h5 {
  color: #545454;
  font-weight: 300;
  font-size: 16px;
  /* font-family: "Source Sans Pro", sans-serif; */
}

.Modal--opaque .ant-btn {
  outline: none;
  height: 36px;
  padding: 0 15px 0 10px;
  border: none;
  border-radius: 5px;
  /* box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2); */
  background-color: #004799;
  color: rgba(255, 255, 255);
  font-size: 14px;
  line-height: 36px;
  transition: background-color 0.25s ease;
}

.Modal--opaque .ant-modal-close {
  position: fixed;
  top: 15px;
  right: 30px;
}

.Modal--opaque .ant-modal-close-x {
  width: 30px;
  height: 30px;
  background-image: url("custom/img/dark-grey-x.svg");
  background-repeat: no-repeat;
  background-size: contain;
  color: rgba(255, 255, 255, 0);
}

.Modal--opaque .success-message {
  text-align: center;
}
/* 
.Modal--opaque h1 {
  margin-bottom: 0;
  font-family: "EB Garamond", serif;
  font-weight: 400;
} */

.Modal--opaque h1 div {
  margin-bottom: 50px;
  color: #7b7b7b;
  font-size: 16px;
}


.Modal--opaque .ant-btn {
  height: 36px;
}

.Modal--opaque div.ant-typography .p {
  font-family: "Source Sans Pro", sans-serif;
}

/* .Modal--opaque .ant-modal-title {
  font-size: 14px;
} */


.Modal-zebra,
.ModalForm-footer {
  padding: 24px;
}

.Modal-zebra--forced,
.Modal-zebra:nth-child(even) {
  background: #f5f5f4;
}

.Modal-zebra > .ant-row:last-child,
.ModalForm-footer > .ant-row:last-child {
  margin-bottom: 0;
}

.VerifyID h1 {
  padding: 50px 0;
  font-size: 24px;
  text-align: center;
}

.VerifyID div.ant-typography {
  padding: 0 15px;
}

.VerifyID .ModalForm-row {
  padding: 15px;
}

.ant-upload-select {
  height: 150px;
  margin: 4px;
  padding: 8px;
  border: 1px dashed rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 767px) {
  .Modal-wrap--opaque {
    padding: 12px;
  }
  .Modal-zebra,
  .ModalForm-footer {
    padding: 18px;
  }
  .Modal-wrap--opaque .ant-modal-close {
    top: 10px;
    right: 20px;
    padding: 15px;
  }
  .Modal-wrap--opaque .ant-modal-close-x {
    width: 20px;
    height: 20px;
  }
}