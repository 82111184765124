html{
  font-size: 1px; /* for using REM units */
}

body{
  color: #222;
  font-size: 16rem;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 200;
  line-height: 1.3;
}
