.l7-marker-container {
  top: 0;
  left: 0;
}

.mapboxgl-canvas-container.mapboxgl-interactive.mapboxgl-touch-drag-pan.mapboxgl-touch-zoom-rotate {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.hospitalTooltipContext {
  padding-bottom: 13px;
  background-color: rgba(41, 71, 157, 1);
  color: rgba(255, 255, 255, 1);
}

.hospitalTooltipContext {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.hospitalTooltipContext h2 {
  padding: 15px 0 0 15px;
  color: #fff;
  font-size: 12px;
  font-family: "EB Garamond", sans-serif;
}

.hospitalTooltipContext span {
  display: block;
  padding-left: 15px;
  color: rgba(255, 255, 255, 1.0);
  font-size: 11px;
}


.hospitalTooltipContent {
  margin-top: 80px;
  margin-bottom: 0;
  padding-bottom: 5px;
  background-color: rgba(248, 249, 248, 1);
}

.hospitalTooltipContent strong {
  display: block;
  text-align: center;
  padding-bottom: 5px;
}


.hospitalTooltipContent li, .tooltipForecast li {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  font-size: 12px;
}

.hospitalTooltipContent button {
  display: block;
  width: 100%;
  min-width: 220px;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px;
  border: 1px solid rgba(188, 54, 25, 1);
  background-color: none;
  color: rgba(188, 54, 25, 1);
  font-size: 12px;
  transition-property: background-color, color;
  transition-duration: 0.33s;
  transition-timing-function: ease;
}

.hospitalTooltipContent button:hover {
  background-color: rgba(188, 54, 25, 1);
  color: rgba(255, 255, 255, 1);
}

.hospitalTooltipContent table {
  width: 100%;
}

.hospitalTooltipContent th {
  width: 33%;
  padding-top: 5px;
  color: rgba(148, 149, 148, 1);
  line-height: 14px;
  text-align: center;
}

.hospitalTooltipContent td {
  padding: 2px;
  text-align: center;
}

.hospitalTooltipContent tr td:nth-child(1) {
  text-align: center;
}



.tooltipContext {
  padding-bottom: 13px;
  background-color: rgba(41, 71, 157, 1);
  color: rgba(255, 255, 255, 1);
  pointer-events: none;
}

.tooltipContext {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.tooltipContext h2 {
  padding: 15px 0 0 15px;
  color: #fff;
  font-size: 16px;
  font-family: "EB Garamond", sans-serif;
  text-align: center;
}

.tooltipContext span {
  display: block;
  padding-left: 15px;
  color: rgba(255, 255, 255, 1.0);
  font-size: 11px;
}

.distanceScore {
  width: 85px;
  padding: 10px 0;
  border-left: 1px solid rgba(83, 107, 176, 1);
  color: rgba(255, 255, 255, 1.0);
  font-size: 10px;
  text-align: center;
}

.distanceScore div {
  margin: -5px 0;
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
}


.tooltipContent {
  margin-top: 50px;
  margin-bottom: 0;
  padding-bottom: 5px;
  background-color: rgba(248, 249, 248, 1);
}


.tooltipForecast {
  padding: 10px 0 5px;
  background-color: rgba(255, 255, 255, 1);
}

.tooltipContent li, .tooltipForecast li {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  font-size: 12px;
}

.tooltipContent button {
  display: block;
  width: 100%;
  min-width: 220px;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px;
  border: 1px solid rgba(188, 54, 25, 1);
  background-color: none;
  color: rgba(188, 54, 25, 1);
  font-size: 12px;
  transition-property: background-color, color;
  transition-duration: 0.33s;
  transition-timing-function: ease;
}

.tooltipContent button:hover {
  background-color: rgba(188, 54, 25, 1);
  color: rgba(255, 255, 255, 1);
}

.tooltipForecast {
  width: 200px;
  text-align: center;
}

.tooltipForecast strong {
  display: block;
  padding-bottom: 0;
  font-size: 12px;
  text-align: center;
}

.tooltipForecast ul li:nth-child(odd) {
  background-color: rgba(246, 246, 246, 1);
  text-align: center;
}

.tooltipForecast ul li {
  padding: 1px 15px;
  text-align: center;
}

.l7-popup-content {
  padding: 0;
}
.l7-popup {
  min-width: 200px;
}

.tooltipContent table {
  width: 100%;
}

.tooltipContent th {
  width: 33%;
  padding-top: 5px;
  color: rgba(148, 149, 148, 1);
  line-height: 14px;
  text-align: center;
}

.tooltipContent td {
  padding: 2px;
  text-align: center;
}

.tooltipContent tr td:nth-child(1) {
  text-align: right;
}


.requested span:nth-child(even) {
  color: #f00;
  font-weight: 700;
}

.none {
  opacity: 1.0;
}

.landscapeNotification {
  display: none;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(232, 234, 234, 1);
}

.landscapeNotification .notification {
  width: 100%;
  padding: 50px;
  text-align: center;
}

.landscapeNotification .notificationLogo {
  width: 150px;
}

.landscapeNotification .notification {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.landscapeNotification span {
  display: block;
  opacity: 0.5;
  font-size: 12px;
}

.landscapeNotification div {
  padding: 30px;
}

.landscapeNotification p {
  margin-bottom: 12px;
  font-size: 12px;
}

@media only screen and (min-width: 480px)
  and (max-width: 700px)
  and (orientation: landscape) {

  .landscapeNotification {
    display: block;
  }
}