.menu {
  display: flex;
  position: absolute;
  z-index: 100;
  top: 15px;
  right: 15px;
  width: auto;
}

.menu .newRequest {
  outline: none;
  height: 36px;
  margin-right: 10px;
  padding: 0 15px 0 10px;
  border: none;
  border-radius: 18px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(1, 115, 185, 1);
  color: rgba(255, 255, 255);
  font-size: 14px;
  line-height: 36px;
  transition: background-color 0.25s ease;
}

.menu .newRequest:hover {
  background-color: rgba(41, 71, 157, 1);
}

.menu .newRequest img {
  position: relative;
  top: -2px;
  margin-right: 5px;
}

.userIcon {
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.userIcon .anticon {
  vertical-align: middle;
  font-size:25px;
}

.userIcon img {
  width: 60%;
  display: block;
  margin: auto;
  top: 0;
  bottom: 0;
  opacity: .8;
  left: 0;
  right: 0;
  position: absolute;
  transition: opacity 0.25s ease;
}

.userIcon:hover img {
  opacity: 0.5;
}

.userMenu ul {
  transform: scale(0);
  position: absolute;
  top: 45px;
  right: 0;
  width: 120px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 1);
  transform-origin: top right;
  transition: transform 0.2s ease;
}
.userMenu:hover ul {
  transform: scale(1);
}

.userMenu .indicator {
  transform: rotate(45deg);
  position: absolute;
  z-index: 0;
  top: -4px;
  right: 13px;
  width: 10px;
  height: 10px;
  background-color: #fff;
}

.userMenu li, .userMenu button {
  width: 100%;
  text-align: right;
}

.userMenu button {
  outline: none;
  height: 40px;
  padding: 0 15px;
  border: none;
  border-bottom: 1px solid rgba(233, 233, 233, 1);
  background-color: rgba(255, 255, 255, 1);
  font-size: 12px;
  line-height: 40px;
  transition: background-color 0.25s ease;
}

.userMenu li:nth-last-child(1) button {
  border-bottom: none;
}

.userMenu button:hover {
  background-color: rgba(247, 247, 247, 1)
}

.userMenu button img {
  opacity: 0.5;
  position: relative;
  top: -1px;
  transition: opacity 0.25s ease;
}

.userMenu button:hover img {
  opacity: 1;
}

.ant-layout-footer, .ant-layout-header {
  padding: 0px;
  background: transparent;
}

.menubox svg {
  font-size: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.menubox {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.mobile-menu .ant-menu {
  background-color: #f8f8f8;
  
  line-height: 30px;
  font-size: 12px; 
  background-color: #f8f8f8;
  font-family: "Source Code Pro";
    letter-spacing: 0.9px;
}


.mobile-dropdown {
  background-color: #f8f8f8;
  font-family: "Source Code Pro";
    font-size: 14px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    width: 200px;
}

.mobile-menu .ant-menu-item {
  padding: 10px;
  border: 1px solid #545454;

  font-weight: 200;
}
.topmenubox svg {
  font-size: auto;
  margin-bottom: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.topmenubox {
  display: block;
  margin-left: auto;
  margin-right: auto;
  line-height: 10px;
  font-size: 12px; 
  text-align: center;
  background-color: #f8f8f8;
  font-family: "Source Code Pro";
    letter-spacing: 0.9px;
}

.searchContainer .ant-col {
  display: flex;
  margin: auto;
}
.search .ant-select {
  max-width: 100% !important;
  padding: 10px;

}

.mobile-top {
  background-color: #f8f8f8;
}

.topmenubox:hover {
  background-color: #FFFFFF;
  color: #004799;
}

/* .topmenubox .ant-badge {
  color: #004799;
} */