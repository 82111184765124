.ModalForm .ant-input,
.ModalForm .ant-input-number,
.ModalForm .ant-select-selector,
.ModalForm .ant-input-password,
.ModalForm .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ModalForm .ant-input-affix-wrapper {
  border-color: #e3e4e3;
  background: #f0f1ef;
}

.ModalForm .ant-form-item-label > label {
  color: #545454;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Source Code Pro", monospace;
}

.ModalForm.ant-form-vertical label {
  height: auto;
}

.ModalForm-footer {
  text-align: center;
}

.ModalForm-item--horizontal.ant-form-item {
  flex-direction: row;
}

.ModalForm-item--horizontal.ant-form-item .ant-form-item-label {
  padding-bottom: 0;
}

.ModalForm-item--horizontal.ant-form-item .ant-form-item-label > label::after {
  margin: 0 8px 0 2px;
}

.ModalForm-item--horizontalReverse.ant-form-item {
  flex-direction: row-reverse;
}

.ModalForm-item--center.ant-form-item {
  justify-content: center;
  text-align: center;
}

.ModalForm-item--center.ant-form-item .ant-form-item-control {
  flex: 0 1 auto;
}

.ModalForm-item--center.ant-form-item .ant-form-item-label {
  text-align: center;
}

.ModalForm-identity h1 {
  font-size: 18px;
  text-align: center;
}

.ModalForm-item--spaceBetween.ant-form-item {
  justify-content: space-between;
}

.ModalForm-item--spaceBetween.ant-form-item .ant-form-item-control {
  flex-grow: 0;
}

.ModalForm-agreement .ant-form-item-label {
  padding-bottom: 0;
}

.ModalForm-agreement .ant-form-item-label > label {
  text-transform: none;
}

.ModalForm-legendTable,
.ModalForm-legendTypography {
  position: relative;
  z-index: 1;
  padding-top: 25;
}

.ModalForm-legendTable::after,
.ModalForm-legendTypography::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #545454;
}

.ModalForm-legendTable table td,
.ModalForm-legendTable table th {
  padding: 0;
}

.ModalForm-legendTable,
.ModalForm-legendTable table {
  width: 100%;
  color: #bababa;
}

.modalform-legendtable table {
  position: relative;
  z-index: 1;
}

.ModalForm-legendTable thead {
  color: #fff;
  font-weight: 700;
}

.ModalForm-legendTable thead th:last-of-type,
.ModalForm-legendTable tr td:last-of-type {
  text-align: right;
}

.ant-form legend {
  padding: 15px;
  font-size: 16px;
}

.ant-form legend.ModalForm-legendTable {
  padding: 15px 0;
}

.ModalForm-legendTypography .ant-typography {
  color: #fff;
}

.ModalForm-legendTypography h1.ant-typography {
  margin-top: 15px;
  font-size: 24px;
  font-family: "EB Garamond", serif;
  font-weight: 400;
}

.ModalForm-legendTypography div.ant-typography {
  margin-bottom: 15px;
  font-size: 16px;
}

.ModalForm-legendTypography h1,
.ModalForm-legendTypography h2,
.ModalForm-legendTypography h3,
.ModalForm-legendTypography h4,
.ModalForm-legendTypography h5 {
  text-align: center;
}

.Modal--opaque .ant-modal-body {
  padding: 0;
}

/*
.Modal--opaque .ant-modal-body form:first-child fieldset .ModalForm-legendTable,
.Modal--opaque .ant-modal-body form:first-child fieldset .ModalForm-legendTypography {
  margin-top: -15px;
}
*/

.ModalForm-row {
  padding: 30px;
}

.ModalForm .ModalForm-row:nth-child(even) {
  background-color: rgba(246, 246, 246, 1);
}

.ModalForm-passwords {
  background-color: rgba(246, 246, 246, 1);
}

.formHeader {
  position: absolute;
  top: 0;
  left: 0;
}

.ant-upload .ant-upload-drag {
  background: transparent;
}

.helenaBrand {
  position: relative;
  top: 15px;
  left: 15px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px;
}

.helenaBrand img {
  height: 20px;
}

.helenaBrand span {
  display: inline-block;
  position: relative;
  top: 2px;
  left: 5px;
  font-family: "Source Code Pro", monospace;
}

.responderSignupForm .ModalForm fieldset > .ant-row {
  padding: 0 15px;
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}